import { environment } from 'src/environments/environment';
import { ICardCollection, ICollectionEditOptions } from 'src/app/models/collection';
import { UploadFileService } from 'src/app/shared/providers/upload-file.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subscription, distinctUntilChanged, of, switchMap, throttleTime } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PageScrollInstance, PageScrollService } from 'ngx-page-scroll-core';
import { ICard, ModelCardRendered, CARD_TYPE, IImgPreloadSanitized, ICollectionDetailsConfig, COLLECTION_CONTROL_BTN, CARD_PURCHASE_STATUS, NAVPANEL_ACTION_TYPE, ModelCardRenderedSelection } from './../../../models/card';
import { Component, EventEmitter, OnInit, Output, Input, ViewEncapsulation, SimpleChanges, AfterViewInit, Inject, ElementRef, ViewChild, NgZone, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { CardsService } from '../../providers/cards.service';
import { SettingsService } from '../../providers/settings.service';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { DOCUMENT } from '@angular/common';
import { UserDataService } from '../../providers/user-data.service';
import { Share } from '@capacitor/share';
import { Keyboard } from '@capacitor/keyboard';
import { CdkDragEnter, moveItemInArray } from '@angular/cdk/drag-drop';
import { UtilityFunctionsService } from '../../providers/utility-functions.service';
import { MobileHelperService } from '../../providers/mobile-helper.service';


@Component({
  selector: 'app-collection-details',
  templateUrl: './collection-details.component.html',
  styleUrls: ['./collection-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
],

})
export class CollectionDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('collectionDetailsRef')
  public collectionDetailsRef: ElementRef;

  @ViewChild('collectionDescriptionTextareaRef')
  public collectionDescriptionTextareaRef: ElementRef;

  @Input() sourceCollection: ICardCollection;
  @Input() collectionEditOptions: ICollectionEditOptions;
  @Input() filterOptions: NAVPANEL_ACTION_TYPE;
  @Input() isBackgroundCoverShown: boolean = false;


  CARD_PURCHASE_STATUS = CARD_PURCHASE_STATUS;

  // @Input() collectionDetailsThumbsData: ModelCardRendered[];
  // @Input() collectionDetailsSource: ModelCardRendered;
  // @Input() collectionDetailsConfig: ICollectionDetailsConfig;

  @Output() collectionLiked = new EventEmitter<ICardCollection>();

  @Output() collectionDetailsThumbClick = new EventEmitter<number>  ();
  @Output() controlButtonClick = new EventEmitter<COLLECTION_CONTROL_BTN> ();


  COLLECTION_CONTROL_BTN = COLLECTION_CONTROL_BTN;

  private collectionDetailsScrollInstance: PageScrollInstance;
  public pageScrollEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  private externalScrollTrigger = new EventEmitter<void>();
  private externalScrollSubscription: Subscription;
  public imagesToPreloadSanitized: IImgPreloadSanitized[];

  private lastLikedBtnElementTarget: HTMLButtonElement;
  public collectionLikeClick: EventEmitter<ICardCollection> = new EventEmitter<ICardCollection>();
  authPopUpCloseSubscription: Subscription;

  constructor(
    public cardService: CardsService,
    public userDataService: UserDataService,
    public settingService: SettingsService,
    public uploadService: UploadFileService,
    private pageScrollService: PageScrollService,
    private domSanitizer: DomSanitizer,
    private ngZone: NgZone,
    private _snackBar: MatSnackBar,
    public utilityService: UtilityFunctionsService,
    public mobileHelperService: MobileHelperService,

    @Inject(DOCUMENT) private document: any) {

      this.collectionLikeClick.pipe(
        throttleTime(500, undefined, { leading: true, trailing: false }),
        switchMap((col) => this.handleCollectionLikeClick(col))
      ).subscribe((response) => { });

     }

  ngOnInit(): void {
    window['angularComponentReference'] = { component: this, zone: this.ngZone, loadAngularFunction: () => this.angularFunctionCalled(), };
    window['angularComponentVar'] = { component: this, zone: this.ngZone, getAngularVar: () => this.getAngularVarCalled(), };

    this.imagesToPreloadSanitized = this.sourceCollection.collectionCards.map((element, index) => {
      return {
        url: element.cardPosterMobile,
        readyToPreload: index === 0 ? true : false,
        urlSanitized: this.domSanitizer.bypassSecurityTrustResourceUrl(element.cardPosterMobile),
      };
    });

    if(this.sourceCollection?.preloadSettings?.numberOfCardsToPreload) {
      const preloadArrLength = this.imagesToPreloadSanitized.length;
      const preloadSettingLength = this.sourceCollection.preloadSettings?.numberOfCardsToPreload;
      const totalPreloadCount = preloadArrLength > preloadSettingLength ? preloadSettingLength : preloadArrLength;
      this.imagesToPreloadSanitized.length = totalPreloadCount;
    }

    if(this.settingService.devicePlatform === 'ios') {
      Keyboard.addListener('keyboardWillShow', info => {
        console.log('keyboard did show with height:', info.keyboardHeight);
        window.document.documentElement.style.setProperty('--keyboard-height', (info.keyboardHeight - 40) + 'px');
      });

      Keyboard.addListener('keyboardWillHide', () => {
        console.log('keyboard did hide');
        window.document.documentElement.style.setProperty('--keyboard-height', '0px');
      });
    }

    this.authPopUpCloseSubscription = this.mobileHelperService.mobileAuthPopupClosed.subscribe((event) => {
        console.log('Auth popup closed', event);
        setTimeout(() => {
        this.userDataService.removeFromSavedCollections(this.sourceCollection, false);
        }, 50);
    });

  }

  dragStart(): void {
    this.utilityService.triggerHaptics();
  }

  dragReleased(): void {
    this.utilityService.triggerHaptics();
  }

  dragEntered(event: CdkDragEnter<number>) {
    const drag = event.item;
    const dropList = event.container;
    const dragIndex = drag.data;
    const dropIndex = dropList.data;

    const phContainer = dropList.element.nativeElement;
    const phElement = phContainer.querySelector('.cdk-drag-placeholder');
    phContainer.removeChild(phElement);
    phContainer.parentElement.insertBefore(phElement, phContainer);

    moveItemInArray(this.sourceCollection.collectionCards, dragIndex, dropIndex);
  }

  public angularFunctionCalled() {
    console.log('SCROLL Angular 2+ function is called');
    this.externalScrollTrigger.emit();
    this.newfunc();
  }

  public getAngularVarCalled(): number {
    return this.settingService.animationRatio;
  }

  newfunc(): void {
    console.log('SCROLL NEW FUNC');
  }

  ngAfterViewInit(): void {
    this.collectionDetailsScrollInstance = this.pageScrollService.create({
      document: this.document,
      scrollFinishListener: this.pageScrollEmitter,
      scrollTarget: '.collection-details-content',
      speed: this.settingService.collectionDetailsScrollSpeed,
      duration: undefined,
      // duration: 450,
      scrollOffset: 0,
      scrollViews: [this.collectionDetailsRef.nativeElement],
      advancedInlineOffsetCalculation: true,
      // easingLogic: (t: number, b: number, c: number, d: number) => {
      //     t /= d/2;
      //     if (t < 1) return c/2*t*t*t + b;
      //     t -= 2;
      //     return c/2*(t*t*t + 2) + b;
      //  }
    });

  }

  public async onShareBtnClicked(collectionToShare: ICardCollection, event?: Event, ): Promise<void> {
    if(event) event.stopPropagation();

    const shared = await this.cardService.shareDataRequest(collectionToShare.collectionId, 'collection');
    const sharedLink = `https://${environment.firebaseConfig.authDomain}/collection/${shared.shared_id}`;

      await Share.share({
        title: `3DWay collection to share: ${collectionToShare.collectionTitle}`,
        text: `${collectionToShare.collectionTitle}`,
        url: sharedLink,
        dialogTitle: 'Sharing 3Dway Collection',
      });
  }

  public preloadFinished(elementIndx: number): void {
    // DISABLED IN COLLECTION-DETAILS DUE TO ERROR IN SAFARI; CHECK LATER;
    // console.log('Img preloaded!', elementIndx);
    if (this.imagesToPreloadSanitized[elementIndx + 1]) {
      this.imagesToPreloadSanitized[elementIndx + 1].readyToPreload = true;
    }
  }

  public isCollectionSaved(collection: ICardCollection): boolean {
    // TODO: Create Logic for like collection/like card;

    return this.userDataService.isCollectionExitsInSaved(collection);
  }

  public selectDescriptionTextarea(): void {
      const inputElem: HTMLInputElement = this.collectionDescriptionTextareaRef.nativeElement;
      inputElem.setSelectionRange(inputElem.value.length, inputElem.value.length)
  }

  public scrollToTopOfCollectionDetails(): Promise<any> {
    return new Promise((resolve, reject) => {
      const tempSubscription = this.pageScrollEmitter.subscribe((ev) => {
          if(ev) {
            resolve(true);
            tempSubscription.unsubscribe();
          }
      } )
      this.pageScrollService.start(this.collectionDetailsScrollInstance);
    });
  }

  public nativeScrollToTopLight(): void {
    this.collectionDetailsRef.nativeElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  public nativeScrollToTop(): Promise<void>{
    // TRICK, TO DISABLE USER SCROLL WHILE ANIMATION/SCROLL WORKS;
    this.collectionDetailsRef.nativeElement.classList.add('overflow-hidden');

    return new Promise((resolve, reject) => {
      this.externalScrollSubscription = this.externalScrollTrigger.subscribe(()=>{
        console.log('SCROLLTO TRIGGERED RESOLVE');
        // clearTimeout(scrollTimeout);
        this.externalScrollSubscription.unsubscribe();
        resolve();
      });

      // const timeoutValue: number = this.settingService.devicePlatform === 'ios' ? 1000 : 0;
      // const scrollTimeout = setTimeout(() => {
      //   console.log('SCROLLTO AUTO RESOLVE');
      //   resolve();
      // }, timeoutValue);

      this.collectionDetailsRef.nativeElement.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

    });
  }

  public dispatchLikeClick() {
    (this.collectionDetailsRef.nativeElement as HTMLElement).getElementsByClassName('collDetails-like-btn')[0].dispatchEvent(new Event('click'));
  }

  public onLikeButtonClicked(collection: ICardCollection, event?: Event): void {

    if(this.userDataService.isUserAuthenticated === false) {
      console.log('ANONYMOUS USER');
      this.userDataService.addToSavedCollections(collection, false);
      this.controlButtonClick.emit(COLLECTION_CONTROL_BTN.ANONYMOUS_USER);
      return;
    }
    if(event) {
      // TODO: Logic of like collection cards;
      event.stopPropagation();
      // Workaround to trigger animation only when 'like' click button was performed;
      const eventTarget: any = event.currentTarget;
      this.lastLikedBtnElementTarget = eventTarget;
      eventTarget.classList.add('touched');
    }

    // this.userDataService.isCollectionExitsInSaved(collection) ? this.userDataService.removeFromSavedCollections(collection) : this.userDataService.addToSavedCollections(collection);
    this.collectionLikeClick.next(collection);


    if(this.userDataService.isCollectionExitsInSaved(collection)) {
      this._snackBar.open('Added to Library.',undefined,{
        horizontalPosition: 'start',
        verticalPosition: 'bottom',
        duration: 750,
        panelClass: 'like-snackbar'
      })
      if(this.settingService.devicePlatform === 'ios') {
        Haptics.impact({
          style: ImpactStyle.Medium
        });
      }
    } else {
      this._snackBar.open('Removed from Library.',undefined,{
        horizontalPosition: 'start',
        verticalPosition: 'bottom',
        duration: 750,
        panelClass: 'like-snackbar'
      })
    }
    // Emit 'Like' event to home page for FooterStateChange;
    this.collectionLiked.emit(collection);
  }

  public getPoster(url: string): string {
    const processedUrl = url ? url : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII='
    return processedUrl
  }

  public preloadError(event: Event): void {
    console.log('preloadErrror ', event)
  }

  public onThumbClick(thumbIndex: number): void {
    this.cardService.modelFeedInitStart = performance.now();

    if(this.collectionEditOptions?.mode === 'active-edit') return;
    console.log('On Thumb clicked (collection details)', thumbIndex);
    this.collectionDetailsThumbClick.emit(thumbIndex);
  }

  public onDeleteSignClick(thumbIndex: number): void {
    console.log('deleteClicked', thumbIndex);
    this.collectionDetailsThumbClick.emit(thumbIndex);
  }


  public onControlButtonClick(event: Event, buttonName: COLLECTION_CONTROL_BTN): void {
    console.log('controlbuttonClick', buttonName)
    event.stopPropagation();
    this.controlButtonClick.emit(buttonName);
  }

  public removeLikeTouchedClass(): void {
    if(this.lastLikedBtnElementTarget) {
      this.lastLikedBtnElementTarget.classList.remove('touched');
    }
  }

  public isCardPurchasedCheckById(card: ICard): boolean {
    return this.userDataService.isCardPurchasedCheckById(card.id);
  }

  public isAllCardsPurchased(): boolean {
    const standartLength = this.sourceCollection.collectionCards.length;
    const filteredCardsLength = this.sourceCollection.collectionCards.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.PURCHASED).length
    if(standartLength === filteredCardsLength) return true;
    if(this.sourceCollection.collectionCards.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.DEFAULT_3DWAY).length === 0) return true
  }

  public hasDraftLikedCards(): boolean {
    return this.sourceCollection.collectionCards.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.DRAFT_LIKED).length > 0;
  }

  public filterCards = (card: ModelCardRenderedSelection) => {
    switch (this.filterOptions) {
      case NAVPANEL_ACTION_TYPE.FILTER_ALL_MODELS :
        return true
      case NAVPANEL_ACTION_TYPE.FILTER_SAVED_MODELS :
        return this.userDataService.isCardExitsInSaved(card) || card.purchaseStatus === CARD_PURCHASE_STATUS.DRAFT_LIKED;
      case NAVPANEL_ACTION_TYPE.FILTER_UPLOADED_MODELS :
        return card.purchaseStatus === CARD_PURCHASE_STATUS.UPLOADED;
      case NAVPANEL_ACTION_TYPE.FILTER_PURCHASE_MODELS :
        return card.purchaseStatus === CARD_PURCHASE_STATUS.PURCHASED;
      case NAVPANEL_ACTION_TYPE.FILTER_REVIEW_MODELS :
        return card.purchaseStatus === CARD_PURCHASE_STATUS.IN_REVIEW || card.purchaseStatus === CARD_PURCHASE_STATUS.PUBLISHED || card.purchaseStatus === CARD_PURCHASE_STATUS.REJECTED;
      default:
        return true
    }
    // if(card.linkedCollectionId !== this.editCollectionSource?.collectionId) return false;
    // return card.uploadStatus.status === UPLOAD_STATUS.INPROGRESS || card.uploadStatus.status === UPLOAD_STATUS.PROCESSING

  }

  public isCustomFilterSelected(): boolean {
    switch (this.filterOptions) {
      case NAVPANEL_ACTION_TYPE.FILTER_SAVED_MODELS :
        return true
      case NAVPANEL_ACTION_TYPE.FILTER_UPLOADED_MODELS :
        return true;
      case NAVPANEL_ACTION_TYPE.FILTER_PURCHASE_MODELS :
        return true
      default:
        return false
    }
  }

  public isFilteringPossible(cards: ICard[]): boolean {
    if(!cards) return false;
    const isAnyCardLiked = cards.filter(card => this.userDataService.isCardExitsInSaved(card));
    const isAnyCardUploaded = cards.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.UPLOADED);
    const isAnyCardPurchased = cards.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.PURCHASED);
    if(isAnyCardLiked.length > 0) return true
    if(isAnyCardUploaded.length > 0) return true
    if(isAnyCardPurchased.length > 0) return true
    return false;
  }

  public isDownloadingPossible(cards: ICard[]): boolean {
    return cards.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.PURCHASED).length > 0
  }

  private handleCollectionLikeClick(collection: ICardCollection): Observable<{ action: string }> {
    if (this.userDataService.isCollectionExitsInSaved(collection)) {
      this.userDataService.removeFromSavedCollections(collection);
      return of({ action: 'removed' });
    } else {
      this.userDataService.addToSavedCollections(collection);
      return of({ action: 'added' });
    }
  }

  ngOnDestroy(): void {
    window.document.documentElement.style.setProperty('--keyboard-height', '0px');
    if(this.settingService.devicePlatform === 'ios') { Keyboard.removeAllListeners(); }
    if(this.authPopUpCloseSubscription) this.authPopUpCloseSubscription.unsubscribe();
  }
}
